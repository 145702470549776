import {Button, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Popper} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';
import React from 'react';

type SXProps = {
  [key: string]: string;
};

type DataInfoPopperProps = {
  sx?: SXProps;
};

const DataInfoPopper = ({sx = {marginTop: '10px', marginLeft: '4px'}}: DataInfoPopperProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'info-popper' : undefined;

  const handleInfo = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleGoToArticle = () => {
    window.open(
      'https://support.fupa.net/support/solutions/articles/75000014572-welche-arten-von-profilen-gibt-es-auf-fupa-wie-kann-man-sie-verwalten-',
      '_blank'
    );
  };

  return (
    <>
      {/*@ts-ignore*/}
      <IconButton color='action' sx={sx} size='small' onClick={handleInfo}>
        <InfoOutlined fontSize='small' />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom-end'}>
        <Paper sx={{maxWidth: '360px', marginLeft: '1rem'}} elevation={2}>
          <DialogTitle sx={{fontSize: '1rem'}}>Woher stammen die Daten?</DialogTitle>
          <DialogContent sx={{fontSize: '0.875rem'}}>
            Du hast von dir selbst ein Profil auf FuPa entdeckt, das du nicht selbst verwaltest? In unserem Hilfebereich
            findest du alle Informationen darüber, woher die Daten aus deinem Profil stammen.
          </DialogContent>
          <DialogActions>
            <Button color='secondary' onClick={handleGoToArticle}>
              Mehr Informationen
            </Button>
          </DialogActions>
        </Paper>
      </Popper>
    </>
  );
};

export default DataInfoPopper;
