import React from 'react';
import {ProfileTeam} from 'app/components/player/PlayerHero/components/ProfileTeam';
import styled from 'styled-components';
import {PlayerName} from 'app/components/player/PlayerHero/components/PlayerName';
import {Avatar} from 'app/components/player/PlayerHero/components/Avatar';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import FollowButton from 'app/components/follow/FollowButton';
import DataInfoPopper from 'app/components/profile/DataInfoPopper';
import {getAge, numberWithThousandSeparator} from 'app/helpers/helpers';
import {useClickTracking} from 'app/hooks/useClickTracking';
import {formatBirthDate} from 'app/helpers/dateHelpers';
import {convertToRoman} from 'app/helpers/convertToRoman';

const Details = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  .currentRole {
    display: block;
    height: 1rem;
  }
  & div {
    overflow: hidden;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.white};
  padding: 1rem;
  margin: auto;
  margin-top: ${SIZES['56']};
  max-width: ${props => props.theme.desktopContentWidth}px;

  ${props => props.theme.desktopLayout`
    margin-top: ${SIZES['72']};
   `}
`;

const StyledPlayerHero = styled.div`
  display: flex;
  background: ${COLORS.white};
  gap: 1rem;
`;

const Extras = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 5rem;

  .caption {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.25rem;
  }

  ${props => props.theme.desktopLayout`
    height: 3.625rem;
  `}
`;

const ExtrasContent = styled(Typography)`
  line-height: 1.5;

  .separator {
    font-size: 1rem;
    padding: 0 0.125rem;
  }
`;

const SCFollowButton = styled(FollowButton)`
  &&& {
    margin: 1rem 0;
  }
`;

type ImageResource = {
  path: string;
  description: string | null;
  source: string | null;
  svg: boolean;
};

type Profile = {
  profile: {
    image: ImageResource | null;
    firstName: string;
    lastName: string;
    currentTeam: any;
    currentRole: any;
    position: string;
    birthday: string;
    details: {
      nickname: string;
      height: number;
      weight: number;
      strongFoot: string;
      nationalities: {
        icon: string;
      }[];
    };
    playerRole: {
      seasons: {
        jerseyNumber: number;
      }[];
    };
  };
};

type Attribute<T> = {
  value: T | undefined;
  format: (value: T) => string;
};

const getExtras = (profile: any): JSX.Element[] => {
  const attributes = [
    {value: profile?.details?.nickname, format: (value: string) => `"${value}"`},
    {value: profile?.birthday, format: (value: string) => `🎂 ${formatBirthDate(value)} (${getAge(value, true)})`},
    {value: profile?.details?.height, format: (value: number) => `${value} cm`},
    {value: profile?.details?.weight, format: (value: number) => `${value} kg`},
    {
      value:
        profile?.details?.strongFoot && profile?.details?.strongFoot !== 'unknown'
          ? profile.details.strongFoot
          : undefined,
      format: (value: string) => `starker Fuß: ${value}`,
    },
    {
      value: profile?.details?.nationalities.length > 0 ? profile?.details?.nationalities : undefined,
      format: (value: any[]) => value.map((nation: any) => nation.icon).join(' '),
    },
  ];

  const extras = attributes
    .filter((attr: Attribute<any>) => attr.value)
    .map((attr: Attribute<any>) => attr.format(attr.value));

  return extras.map((extra, index) => (
    <>
      {extra}
      {index < extras.length - 1 && <span className={'separator'}> | </span>}
    </>
  ));
};

const getCurrentTeam = (currentTeam: any): {name: string; url: string; image: any} | null => {
  if (currentTeam) {
    const level = currentTeam.level > 1 ? convertToRoman(currentTeam.level) : '';
    return {
      name:
        currentTeam?.competition?.slug === 'uebergangsliga'
          ? currentTeam.name.middle + ' ' + currentTeam?.ageGroup.name
          : `${currentTeam.name.middle} ${level}`,
      url: `/team/${currentTeam.slug}`,
      image: currentTeam.image,
    };
  }
  return null;
};
export const PlayerHero = ({profile}: Profile): JSX.Element => {
  const {clicks} = useClickTracking('player', profile);
  const currentTeam = getCurrentTeam(profile?.currentRole?.team);

  const currentRole = profile?.currentRole?.position || profile?.position;
  const extras = getExtras(profile);

  return (
    <Card>
      <StyledPlayerHero>
        <Avatar image={profile.image} jerseyNumber={profile?.playerRole?.seasons?.[0]?.jerseyNumber} />
        <Details>
          <Wrapper>
            <div>
              <Typography className='currentRole' variant={'caption1'}>
                {currentRole}
              </Typography>
              <PlayerName firstName={profile.firstName} lastName={profile.lastName} />
            </div>
            <DataInfoPopper sx={{marginLeft: 'auto', alignSelf: 'self-start', top: '0.25rem'}} />
          </Wrapper>
          <ProfileTeam currentTeam={currentTeam} />
          <SCFollowButton adjustStyles={false} isDesktop={true}></SCFollowButton>
        </Details>
      </StyledPlayerHero>
      <Extras>
        <div className={'caption'}>
          <Typography variant={'caption1'}>{extras ? 'Persönliches' : ''}</Typography>
          <Typography variant={'caption1'}>
            {clicks ? `Aufrufe: ${numberWithThousandSeparator(clicks)}` : ''}
          </Typography>
        </div>
        <ExtrasContent variant={'body1'}>{extras}</ExtrasContent>
      </Extras>
    </Card>
  );
};
