import React, {useCallback, useReducer} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import {ProfileStations} from 'app/components/profile/ProfileStations';
import {string} from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {LoadingIndicator, LOADING_MESSAGE, COLORS, ELEVATIONS, SIZES} from '@fupa/fupa-uikit';
import {AdSlot} from 'app/components/ads/AdSlot';
import {getAge} from 'app/helpers/helpers';
import {ProfileStationsWithStats} from 'app/components/profile/profile.constants';
import encodeHTML from 'app/helpers/encodeHTMLInStrings';
import styled from 'styled-components';
import {Subheading} from 'app/styles/heading';
import {EditFabContainer} from 'app/components/floatingAction/EditFab';
import {useIsDesktop} from 'app/components/ads/useIsDesktop';
import {useSelector} from 'react-redux';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {fetchPlayerProfileStationMatches} from 'app/routes/player/PlayerPageActions';
import {useParams} from 'react-router';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {PlayerProfilePageReducer} from 'app/routes/player/PlayerPageReducer';
import StickyMobileBannerAdSlot from 'app/components/ads/StickyMobileBannerAdSlot';

const Container = styled.div`
  background-color: ${COLORS.white};
  box-shadow: ${ELEVATIONS.Card};
  margin-top: ${SIZES['8']};
`;

const Heading = styled(Subheading)`
  &&& {
    margin: 0;
  }
`;

const getCurrentSeason = role => {
  if (!role?.seasons) {
    return null;
  } else {
    const lastSeason = role.seasons[0];
    const isActive = lastSeason.team?.competition?.active ?? false;
    return isActive ? lastSeason : null;
  }
};

const getInfo = ({coachRole, playerRole}) => {
  const currentCoachSeason = getCurrentSeason(coachRole);
  const currentPlayerSeason = getCurrentSeason(playerRole);
  if (currentCoachSeason && ProfileStationsWithStats.includes(currentCoachSeason.position)) {
    // return coach statistics for positions 'Trainer', 'Spielertrainer', 'Co-Trainer', 'Co-Spielertrainer'
    const {matches, wins, points} = coachRole.total;
    return `🎽 ${matches} Spiele 🥇 ${wins} Siege 🎲 ${points} Punkte`;
  } else if (currentPlayerSeason) {
    // return player statistics for players that are not also 'Trainer', 'Spielertrainer', 'Co-Trainer', 'Co-Spielertrainer'
    const {matches, goals, assists} = playerRole.total;
    return `🎽 ${matches} Spiele ⚽ ${goals} Tore ⭐ ${assists} Vorlagen`;
  } else {
    // return no info for profiles that are neither player nor in the positions 'Trainer', 'Spielertrainer', 'Co-Trainer', 'Co-Spielertrainer'
    return null;
  }
};

const getPosition = player => {
  return player.currentRole?.position ?? player.position ?? null;
};

const getSchema = player => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: `${player.firstName} ${player.lastName}`,
    image: {
      '@type': 'ImageObject',
      url: player.image?.path ? `${player.image.path}960x1200.jpeg` : '',
      width: 960,
      height: 1200,
      author: player.image?.source ?? 'Unbekannt',
    },
    birthDate: player.birthday ?? '',
    familyName: player.lastName,
    givenName: player.firstName,
    alternateName: player.details?.nickname ?? '',
    nationality: player.details?.nationalities?.map(nationality => ({
      '@type': 'Country',
      name: nationality.name,
    })),
    url: 'https://www.fupa.net/player/' + player.slug,
    memberOf: player.currentTeam
      ? {
          '@type': 'SportsOrganization',
          sport: 'Soccer',
          name: player.currentTeam.club?.name,
          url: 'https://www.fupa.net/club/' + player.currentTeam.club?.slug,
          logo: {
            '@type': 'ImageObject',
            url: player.currentTeam.club?.image?.path ? player.currentTeam.club?.image.path + '800x800.png' : '',
            width: 800,
            height: 800,
          },
        }
      : {},
  };

  return encodeHTML(JSON.stringify(schema));
};

const getMetaData = (player, title) => {
  const playerData = {
    name: `${player.firstName} ${player.lastName}`,
    nickName: player.details?.nickname ? `Spitzname "${player.details.nickname}"` : null,
    club: player.currentRole?.team?.name?.full ?? 'ohne Verein',
    competition: player.currentRole?.team?.competition?.name ? `${player.currentRole?.team?.competition?.name}️` : null,
    age: player.birthday ? `${getAge(player.birthday)}` : null,
    position: getPosition(player),
    info: getInfo(player),
  };
  const description = Object.values(playerData)
    .map(value => value)
    .filter(value => value)
    .join(' ➤ ');
  const ogMeta = getOgMetaData(title, description, `/player/${player.slug}`, player.image?.path);
  const robots = {name: 'robots', content: player.archivedDate ? 'noindex, follow' : 'index, follow'};
  return [{name: 'description', content: description}, robots, ...ogMeta];
};

const getOgMetaData = (title, description, pathname, imagePath) => {
  let meta = [
    {property: 'og:title', content: title},
    {property: 'og:description', content: description},
    {property: 'og:url', content: `https://www.fupa.net${pathname}`},
    {property: 'og:type', content: 'profile'},
    {property: 'og:locale', content: 'de_DE'},
    {property: 'fb:app_id', content: '939336572887264'},
  ];

  const imageSize = {width: '960', height: '1200'};
  const imageUrl = imagePath ? `${imagePath}${imageSize.width}x${imageSize.height}.jpeg` : '';

  const ogImage = imageUrl
    ? [
        {property: 'og:image', content: imageUrl},
        {property: 'og:image:secure_url', content: imageUrl},
        {property: 'og:image:type', content: 'image/jpeg'},
        {property: 'og:image:width', content: `${imageSize.width}px`},
        {property: 'og:image:height', content: `${imageSize.height}px`},
      ]
    : [];

  meta = meta.concat(ogImage);

  return meta;
};

const PlayerProfilePage = ({defaultTitle, data, isFetching}) => {
  const topValue = '0rem';
  const isAuthenticated = useSelector(selectAuthState);
  const isDesktop = useIsDesktop();
  const {playerSlug} = useParams();

  const {restoredData} = useRestoreData('PlayerProfilePage');
  const initData = {
    matches: {},
  };
  const initialStateDetails = restoredData ?? initData;
  const [state, dispatch] = useReducer(PlayerProfilePageReducer, initialStateDetails);
  const {matches} = state;

  // use const instead of component state to avoid to start auction for `Rectangle_1` twice
  const rect1 = data.coachRole?.seasons?.length > 4;

  const meta = useCallback(() => getMetaData(data, defaultTitle), [data, defaultTitle]);
  const schema = useCallback(() => getSchema(data), [data]);

  const loadStation = profileSeasonId => fetchPlayerProfileStationMatches(playerSlug, profileSeasonId)(dispatch, state);

  // Store data for "matches" in history
  useFetchData(state, () => {}, 'PlayerProfilePage');

  let coachSeasons = null;
  if (data.coachRole?.seasons) {
    let adUnits;
    if (data.coachRole.seasons?.length > 4) {
      adUnits = ['Rectangle_1'];
    }
    coachSeasons = (
      <ProfileStations
        role='Trainer'
        stations={data.coachRole}
        loadStation={loadStation}
        matches={matches}
        dispatch={dispatch}
        adUnits={adUnits}
      />
    );
  }

  let playerSeasons = null;
  if (data.playerRole?.seasons) {
    let adUnits;
    if (data.playerRole.seasons?.length > 4) {
      adUnits = rect1 ? ['Rectangle_2'] : ['Rectangle_1', 'Rectangle_2'];
    }
    playerSeasons = (
      <ProfileStations
        role='Spieler'
        stations={data.playerRole}
        loadStation={loadStation}
        matches={matches}
        dispatch={dispatch}
        adUnits={adUnits}
      />
    );
  }

  const editBtn =
    isAuthenticated && data?.id ? (
      <EditFabContainer
        hide={!isDesktop}
        url={`${process.env.ADMIN_URL}/fupa/admin/spielerprofil.php?spieler=${data.id}`}
      />
    ) : null;

  const showContent = isFetching ? (
    <LoadingIndicator message={LOADING_MESSAGE.loadingData} bgColor />
  ) : (
    <>
      <AdSlot adUnit='Content_1' adLayout={'mobile'} />
      <Container>
        <Heading variant='subheading2'>Laufbahn</Heading>
        {coachSeasons}
        {playerSeasons}
      </Container>
    </>
  );

  return (
    <>
      <ContentAdDesktop top={topValue} bgColor fabBtn={editBtn} contentPaddingBottom={'4.5rem'}>
        <Helmet meta={meta()}>
          <script type='application/ld+json'>{schema()}</script>
        </Helmet>
        {showContent}
      </ContentAdDesktop>
      <StickyMobileBannerAdSlot />
    </>
  );
};

PlayerProfilePage.propTypes = {
  metaDescription: string.isRequired,
};

export default PlayerProfilePage;
