import React from 'react';
import styled from 'styled-components';
import {FlexContainer} from 'app/styles/content';
import ProfileSeasonHeader from 'app/components/profile/ProfileSeasonHeader';
import {object, string} from 'prop-types';
import ProfileMatches from 'app/components/profile/ProfileMatches';
import {updateStationShow} from 'app/routes/player/PlayerPageActions';

const Container = styled(FlexContainer)`
  position: relative;
  flex-direction: column;
  flex: 0 100%;
`;

const playerStatisticKeys = {
  matches: 'Spiele',
  goals: 'Tore',
  assists: 'Assists',
};

const coachStatisticKeys = {
  wins: 'S',
  draws: 'U',
  defeats: 'N',
};

const TeamSeasonStation = ({
  updateFirstOpenedStation,
  firstOpenedStation,
  station,
  role,
  loadStation,
  matches,
  dispatch,
}) => {
  const profileSeasonId = station.id;
  const {items, show} = matches[profileSeasonId] ?? {
    items: null,
    show: false,
  };

  const setShow = (key, show) => dispatch(updateStationShow({key, show}));

  const onClick = () => {
    if (!show && !items) {
      updateFirstOpenedStation(profileSeasonId);
      loadStation(profileSeasonId);
    } else {
      setShow(profileSeasonId, !show);
    }
  };

  return (
    <Container>
      <ProfileSeasonHeader
        station={station}
        role={role}
        onClick={onClick}
        statisticKeys={role === 'coach' ? coachStatisticKeys : playerStatisticKeys}
      />
      <ProfileMatches
        firstOpenedStation={firstOpenedStation}
        station={station}
        role={role}
        matches={matches}
        dispatch={dispatch}
      />
    </Container>
  );
};

TeamSeasonStation.propTypes = {
  station: object.isRequired,
  role: string.isRequired,
};

export {TeamSeasonStation};
