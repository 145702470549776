import React, {useState} from 'react';
import {CardHeading, CardSubheading} from 'app/styles/heading';
import {numberWithThousandSeparator} from 'app/helpers/helpers';
import {formatBirthDate} from 'app/helpers/dateHelpers';
import {COLORS, Image, Typography} from '@fupa/fupa-uikit';
import {
  ProfileCard,
  ProfileInfoColumn,
  ProfileInfoColumnContainer,
  ClickableProfilePhoto,
  ProfilePhotoWrapper,
  ProfileStats,
  ProfileStatsData,
} from 'app/components/profile/ProfileStyles';
import {TextWithLabel, TextWithLabelJustifyContainer} from 'app/components/textWithLabel/TextWithLabel';
import {FullscreenImage} from 'app/components/backdrop/FullscreenImage';
import {useClickTracking} from 'app/hooks/useClickTracking';
import {Person, PhotoCamera, Visibility} from '@mui/icons-material';
import DataInfoPopper from 'app/components/profile/DataInfoPopper';

const RefereeInfo = ({referee}) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [detailImage, setDetailImage] = useState(null);
  const facts = {
    function: referee.function ? referee.function : '–',
    group: referee.group
      ? {name: referee.group.name, url: `/referee-group/${referee.group.slug}`, image: referee.group.image}
      : {name: '–', url: '', image: null},
    birthday: referee.birthday ? `${formatBirthDate(referee.birthday)} (${referee.age})` : referee.age ?? '-',
    nationalities: referee?.nationalities?.length ? referee?.nationalities?.map(nation => nation.icon).join(' ') : '–',
    location: referee.location ? referee.location : '–',
    achievements: referee.achievements ? referee.achievements : '–',
    since: referee.since ? referee.since : '–',
    club: referee.club
      ? {name: referee.club.middleName, url: `/club/${referee.club.slug}`, image: referee.club.image}
      : {name: '–', url: '', image: null},
  };

  const refereePhoto = referee.image ? (
    <Image entity='referee' circle={true} src={referee.image} ratio='1:1' height={100} width={100} />
  ) : (
    <Person style={{fontSize: 100}} />
  );

  let refereePhotoSource =
    referee.image && referee.image.source ? (
      <ProfileStatsData>
        <PhotoCamera style={{color: COLORS.darkGrey, fontSize: 16}} />
        <Typography variant='caption1'>{referee.image.source}</Typography>
      </ProfileStatsData>
    ) : (
      <div />
    );

  const handleClose = () => {
    setFullscreen(false);
  };

  const handleOpen = detailImage => {
    setFullscreen(true);
    setDetailImage(detailImage);
  };

  const {clicks} = useClickTracking('referee', referee);

  return (
    <ProfileCard>
      <CardHeading>
        <CardSubheading variant='subheading2'>Schiedsrichterdaten</CardSubheading>
        <DataInfoPopper />
      </CardHeading>
      <TextWithLabelJustifyContainer>
        {/*  1st Row - 1st Column*/}
        <ProfileInfoColumnContainer>
          <ProfileInfoColumn>
            <TextWithLabel label='Funktion' data={facts.function} />
            <TextWithLabel
              label='Aktuelle Gruppe'
              data={facts.group.name}
              link={facts.group.url}
              img={{src: facts.group.image, type: 'referee-group'}}
              decoration={facts.group.name !== '–'}
            />
          </ProfileInfoColumn>
          {/*  1st Row - 2nd Column*/}
          <ProfilePhotoWrapper>
            <ClickableProfilePhoto onClick={() => handleOpen(referee.image)}>{refereePhoto}</ClickableProfilePhoto>
            <FullscreenImage entity='referee' imageSrc={detailImage} onClick={handleClose} open={!!fullscreen} />
          </ProfilePhotoWrapper>
          {/*  1st Row - 3td Column*/}
          <ProfileInfoColumn>
            <TextWithLabel alignment='right' label='Schiri seit' data={facts.since} />
            <TextWithLabel
              alignment='right'
              label='Aktueller Verein'
              data={facts.club.name}
              link={facts.club.url}
              img={{src: facts.club.image, type: 'emblem'}}
              decoration={facts.club.name !== '–'}
            />
          </ProfileInfoColumn>
        </ProfileInfoColumnContainer>
      </TextWithLabelJustifyContainer>
      {/*  2nd Row */}
      <ProfileInfoColumnContainer justify='center'>
        <TextWithLabel label='Erfolge' data={facts.achievements} />
      </ProfileInfoColumnContainer>
      {/*  3td Row */}
      <CardSubheading variant='subheading2'>Persönliche Daten</CardSubheading>
      <TextWithLabelJustifyContainer justify='space-between'>
        <TextWithLabel
          noPaddingBottom={true}
          label={!referee.birthday && referee.age ? 'Alter' : 'Geburtstag'}
          data={facts.birthday}
        />
        <TextWithLabel noPaddingBottom={true} label='Ort' data={facts.location} />
        <TextWithLabel noPaddingBottom={true} label='Nationalität' data={facts.nationalities} />
      </TextWithLabelJustifyContainer>
      <ProfileStats>
        {refereePhotoSource}
        <ProfileStatsData>
          <Visibility style={{color: COLORS.darkGrey, fontSize: 16}} />
          <Typography variant='caption1'>{numberWithThousandSeparator(clicks)}</Typography>
        </ProfileStatsData>
      </ProfileStats>
    </ProfileCard>
  );
};

export default RefereeInfo;
