import React, {useState} from 'react';
import styled from 'styled-components';
import {COLORS, FONTS, Image, ImageEntity, TextBadge} from '@fupa/fupa-uikit';
import {FullscreenImage} from 'app/components/backdrop/FullscreenImage';

const AVATAR_SIZE = 150;

const StyledAvatar = styled.div`
  height: ${AVATAR_SIZE}px;
  position: relative;
  width: ${AVATAR_SIZE}px;
  cursor: pointer;

  .avatar {
    border: 1px solid ${COLORS.lighterGrey};
  }
`;

const JerseyNumber = styled(TextBadge)`
  &&& {
    width: 2.375rem;
    height: 2.375rem;
    font-size: ${FONTS['18']};
    right: 0;
    z-index: 0;
  }
`;

export const Avatar = ({image, jerseyNumber}): JSX.Element => {
  const [fullscreen, setFullscreen] = useState(false);
  const handleClose = () => {
    setFullscreen(false);
  };

  const handleOpen = () => {
    setFullscreen(true);
  };

  const JerseyNumberBadge = jerseyNumber ? <JerseyNumber value={jerseyNumber.toString()} position={1} /> : null;

  const Photo = image ? (
    <Image
      className={'avatar'}
      entity={ImageEntity.Player}
      circle={true}
      src={image}
      ratio='1:1'
      height={AVATAR_SIZE}
      width={AVATAR_SIZE}
      eager={true}
      fetchPriority='high'
    />
  ) : null;

  const fullscreenImage = image ? {...image, description: image.source ? `Foto: ${image.source}` : ''} : null;
  return (
    <>
      <StyledAvatar onClick={handleOpen}>
        {JerseyNumberBadge}
        {Photo}
      </StyledAvatar>
      <FullscreenImage entity='player' imageSrc={fullscreenImage} onClick={handleClose} open={fullscreen} />
    </>
  );
};
