import React, {useReducer} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import RefereeInfo from 'app/components/referee/RefereeInfo';
import {useDispatch, useSelector, useStore} from 'react-redux';
import RefereeStations from 'app/components/referee/RefereeStations';
import {Helmet} from 'react-helmet-async';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {LoadingIndicator, LOADING_MESSAGE} from '@fupa/fupa-uikit';
import {fetchReferee, fetchRefereeStationMatches} from 'app/routes/referee/RefereePageActions';
import {RefereePageReducer} from 'app/routes/referee/RefereePageReducer';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {EditFabContainer} from 'app/components/floatingAction/EditFab';
import {useParams} from 'react-router';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';

const getMetaInformation = () => {
  const header = useSelector(state => state.header);
  const title = `${header.title} - Schiedsrichter${TITLE_POSTFIX}`;
  const clubName = header.parent.name === 'ohne Verein' ? '' : ` vom Verein ${header.parent.name}`;
  const meta = [
    {name: 'description', content: `Schiedsrichterprofil von ${header.title}${clubName}. Alle Statistiken gibts hier.`},
    {name: 'robots', content: 'noindex, follow'},
  ];
  return {title, meta};
};

const RefereePage = () => {
  const {restoredData} = useRestoreData('RefereePage');
  const {refereeSlug} = useParams();
  const isAuthenticated = useSelector(selectAuthState);
  const {title, meta} = getMetaInformation();
  const dispatchRedux = useDispatch();
  const {getState} = useStore();

  const initialState = restoredData ?? {
    referee: {},
    isFetching: false,
    matches: {},
  };
  const [state, dispatch] = useReducer(RefereePageReducer, initialState);

  const loadData = () => fetchReferee(refereeSlug, dispatchRedux, getState)(dispatch, state);
  const loadMatches = seasonSlug => fetchRefereeStationMatches(refereeSlug, seasonSlug)(dispatch, state);
  const {referee, isFetching, matches} = state;
  useFetchData(state, loadData, 'RefereePage');

  const refereeRole = referee.refereeRole ? referee.refereeRole : {};

  const showInfo = isFetching ? (
    <LoadingIndicator message={LOADING_MESSAGE.loadingData} bgColor />
  ) : (
    <>
      <RefereeInfo referee={referee} />
      <RefereeStations refereeRole={refereeRole} loadMatches={loadMatches} matches={matches} dispatch={dispatch} />
    </>
  );

  const editBtn =
    isAuthenticated && referee?.id ? (
      <EditFabContainer url={`${process.env.ADMIN_URL}/fupa/admin/sr_schiri_profil.php?id=${referee.id}`} />
    ) : null;

  return (
    <ContentAdDesktop noTabs top='4.5rem' bgColor fabBtn={editBtn}>
      <Helmet title={title} meta={meta} />
      {showInfo}
    </ContentAdDesktop>
  );
};

export default RefereePage;
