import React from 'react';
import styled from 'styled-components';
import {COLORS, ELEVATIONS, SIZES, ToggleIcon, Typography} from '@fupa/fupa-uikit';

const Container = styled.div`
  display: flex;
  flex: 0 100%;
  padding: ${SIZES['8']} ${SIZES['16']};
  box-shadow: ${props => (props.show ? ELEVATIONS.ToggleHeader : 'none')};
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.lightestGrey};
  }
`;

const Title = styled(Typography)`
  flex: 1 30%;
  display: flex;
  align-items: center;
`;

const League = styled.div`
  flex: 1 55%;
  display: flex;
  flex-direction: column;
`;

const Matches = styled(League)`
  flex: 1 15%;
  min-width: 4rem;
  align-items: center;
`;

const ToggleIconDesktop = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  max-width: 4rem;
`;

const RefereeSeasonHeader = ({station, show, onClick}) => {
  const seasonLevel = station.level ? station.level : 'unbekannt';

  return (
    <Container show={show} onClick={onClick}>
      <Title variant='body1'>{station.season.name}</Title>
      <League>
        <Typography variant='body1'>{seasonLevel}</Typography>
        <Typography variant='caption1'>Klasse</Typography>
      </League>
      <Matches>
        <Typography variant='body1'>{station.matches}</Typography>
        <Typography variant='caption1'>Spiele</Typography>
      </Matches>
      <ToggleIconDesktop>
        <ToggleIcon open={show} style={{color: COLORS.darkGrey}} />
      </ToggleIconDesktop>
    </Container>
  );
};

export default RefereeSeasonHeader;
