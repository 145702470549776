import {useEffect, useRef, useState} from 'react';

const useDetectSticky = (ref, observerSettings = {threshold: [1]}) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef();
  ref ||= newRef;

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio < 1), observerSettings);

    observer.observe(cachedRef);

    return () => {
      observer.unobserve(cachedRef);
    };
  }, []);

  return isSticky;
};

export default useDetectSticky;
