const refereeStationFilter = {
  yellowCards: false,
  yellowRedCards: false,
  redCards: false,
};

const RefereePageReducer = (state, action) => {
  switch (action.type) {
    case 'REFEREE_FETCHING':
      return {...state, isFetching: true};
    case 'REFEREE_UPDATE':
      return {...state, isFetching: false, referee: action.data};
    case 'REFEREE_STATION_MATCHES_FETCHING':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            items: [],
            error: null,
            isFetching: true,
            show: true,
            filter: refereeStationFilter,
          },
        },
      };
    case 'REFEREE_STATION_MATCHES_UPDATE':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            items: action.data.items,
            error: null,
            isFetching: false,
            show: true,
            filter: refereeStationFilter,
          },
        },
      };
    case 'REFEREE_STATION_MATCHES_ERROR':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            items: [],
            error: action.data.error,
            isFetching: false,
            show: true,
            filter: refereeStationFilter,
          },
        },
      };
    case 'REFEREE_STATION_SHOW_UPDATE':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            ...state.matches[action.data.key],
            show: action.data.show,
          },
        },
      };
    case 'REFEREE_STATION_FILTER_UPDATE':
      return {
        ...state,
        matches: {
          ...state.matches,
          [action.data.key]: {
            ...state.matches[action.data.key],
            filter: action.data.filter,
          },
        },
      };
    default:
      return state;
  }
};

export {RefereePageReducer, refereeStationFilter};
