import React from 'react';
import styled from 'styled-components';
import {CaptionContainer} from 'app/styles/heading';
import {
  LoadingIndicator,
  LOADING_MESSAGE,
  ELEVATIONS,
  SIZES,
  Chip,
  ChipIconContainer,
  ChipLabel,
  Chips,
  Placeholder,
  Line,
  Typography,
  COLORS,
} from '@fupa/fupa-uikit';
import {FlexContainer} from 'app/styles/content';
import ProfileMatchesFilterContainer from 'app/components/profile/ProfileMatchesFilterContainer';
import RefereeSeasonHeader from 'app/components/referee/RefereeSeasonHeader';
import {updateStationShow, updateStationFilter} from 'app/routes/referee/RefereePageActions';
import {refereeStationFilter} from 'app/routes/referee/RefereePageReducer';
import {Card, TwoCards} from 'app/components/icons';

const Container = styled(FlexContainer)`
  flex-direction: column;
  flex: 0 100%;
`;

const LeagueBlock = styled.div`
  padding: ${SIZES['8']} 0;
  box-shadow: ${props => (props.show ? ELEVATIONS.ToggleBody : 'none')};
`;

const RefereeSeason = ({station, matches, loadMatches, dispatch}) => {
  const key = station.season.slug;
  const {items, isFetching, error, show, filter} = matches[key] ?? {
    items: [],
    isFetching: false,
    error: null,
    show: false,
    filter: refereeStationFilter,
  };

  const setShow = (key, show) => dispatch(updateStationShow({key, show}));
  const setFilter = data => dispatch(updateStationFilter(data));

  const FilterGenerator = statisticKey =>
    ({
      yellowCards: {label: 'Gelb', icon: <Card style={{color: COLORS.yellow, fontSize: 16, margin: 'auto'}} />},
      yellowRedCards: {label: 'Gelb-Rot', icon: <TwoCards style={{fontSize: 16, margin: 'auto'}} />},
      redCards: {label: 'Rot', icon: <Card style={{color: COLORS.red, fontSize: 16, margin: 'auto'}} />},
    }[statisticKey]);

  const handleFilterClick = filterValue => {
    if (filterValue in filter) {
      setFilter({key, filter: {...filter, [filterValue]: !filter[filterValue]}});
    }
  };
  const onClick = () => {
    if (!show && !items.length) {
      loadMatches(key);
    }
    setShow(key, !show);
  };

  const checkMatches = isFetching ? (
    <LoadingIndicator message={LOADING_MESSAGE.loadingMatches} bgColor />
  ) : station.matches === 0 && !isFetching && !error ? (
    <Placeholder>Noch keine Spiele eingetragen.</Placeholder>
  ) : error ? (
    <Placeholder>Beim Laden der Spiele ist ein Fehler aufgetreten.</Placeholder>
  ) : null;

  const leagueBlock =
    items.length > 0
      ? items.map(matchGroup => (
          <ProfileMatchesFilterContainer
            key={matchGroup.competition.competitionSeasonId}
            matches={matchGroup.matches}
            competition={matchGroup.competition}
            station={station}
            filter={filter}
            type={'referee'}
          />
        ))
      : checkMatches;

  let showLeagueBlock = show ? <LeagueBlock show={show}>{leagueBlock}</LeagueBlock> : null;
  let showLine = !show ? <Line /> : null;
  let filterChips = null;

  if (show) {
    const statisticFilterChip = Object.keys(station)
      .map(filterValue => {
        const filterOptions = FilterGenerator(filterValue);
        if (filterOptions && station[filterValue] > 0) {
          return (
            <Chip key={filterValue} active={filter[filterValue]} onClick={() => handleFilterClick(filterValue)}>
              <ChipIconContainer>{filterOptions.icon}</ChipIconContainer>
              <ChipLabel>{station[filterValue] + ' ' + filterOptions.label}</ChipLabel>
            </Chip>
          );
        }
      })
      .filter(filter => filter);

    filterChips = statisticFilterChip.length ? (
      <div>
        <CaptionContainer>
          <Typography variant='caption1'>Filter</Typography>
        </CaptionContainer>
        <Chips>{statisticFilterChip}</Chips>
      </div>
    ) : null;
  }

  return (
    <Container>
      <RefereeSeasonHeader onClick={onClick} station={station} show={show} />
      {filterChips}
      {showLeagueBlock}
      {showLine}
    </Container>
  );
};

export default RefereeSeason;
