import {useEffect, useState} from 'react';
import {updateFollowEntity} from 'app/services/follow';
import {useDispatch, useSelector} from 'react-redux';
import {askForEntityPush, askForEntityPushFinished, fetchFollows} from 'app/actions/currentUserActions';
import {selectCurrentUser, selectFollowedEntities} from 'app/hooks/reduxCreateSelectorHooks';
import {updateSnackbarInfo} from 'app/components/snackbar/SnackbarActions';
import {
  commonUpdateError,
  followError,
  unauthorizedFollow,
  limitError,
  autoPushSuccess,
} from 'app/components/snackbar/SnackbarContent';
import {convertToRoman} from 'app/helpers/convertToRoman';
import {collectFollowEntity} from 'app/components/follow/trackFollowPushEvent';
import {useLocation} from 'react-router';

const getEntity = entity => {
  const mapEntityType = {
    teamseason: 'team',
    player: 'profile',
  };

  if (entity.entity) {
    return entity.entity;
  } else {
    if (entity.type) {
      return mapEntityType[entity.type] ?? entity.type;
    } else {
      return null;
    }
  }
};

export const useFollow = (followEntity, setOpen, disableAskForPush = false, origin) => {
  const listOfFollowedEntities = useSelector(selectFollowedEntities);
  const listOfTeams = listOfFollowedEntities.filter(entity => getEntity(entity) === 'team');
  const listOfOthers = listOfFollowedEntities.filter(entity => getEntity(entity) !== 'team');
  const {newFollow} = useSelector(selectCurrentUser);
  const autoPush = !!Object.keys(listOfFollowedEntities).find(entity => listOfFollowedEntities[entity].push);
  const dispatch = useDispatch();
  const location = useLocation();

  const [isFollowed, setIsFollowed] = useState(false);

  const currentEntityFollowed =
    listOfOthers.find(entity => entity.id === followEntity.id) ||
    listOfTeams.find(team => {
      if (Object.keys(followEntity).length && followEntity.teams) {
        return followEntity.teams.find(teamRoot => {
          if (teamRoot.id === team.id) {
            return team;
          }
        });
      }
    });

  useEffect(() => {
    return () => {
      if (newFollow.askForPush) {
        dispatch(askForEntityPushFinished());
      }
    };
  }, [location.pathname, newFollow.askForPush]);

  useEffect(() => {
    setIsFollowed(!!currentEntityFollowed);
  }, [currentEntityFollowed]);

  const handleSG = (teamId, teams) => {
    setIsFollowed(true);
    updateFollowEntity('team', teamId, !!currentEntityFollowed, autoPush)
      .then(() => {
        const team = teams.find(team => {
          return team.id === parseInt(teamId);
        });
        const teamName = team.level > 1 ? team.names.middle + ' ' + convertToRoman(team.level) : team.names.middle;
        const teamEntity = {
          entity: 'team',
          name: teamName,
          ...team,
        };
        if (!currentEntityFollowed) {
          collectFollowEntity(teamEntity.entity, teamEntity.slug);
        }
        if (!autoPush && !disableAskForPush) {
          dispatch(askForEntityPush(teamEntity));
        } else {
          const message = autoPushSuccess(teamName);
          dispatch(updateSnackbarInfo(message));
        }
        dispatch(fetchFollows());
        if (setOpen) {
          setOpen(false);
        }
      })
      .catch(err => {
        const name = 'Dem Team';
        let info = followError(name);
        if (err.response?.status === 400 && err.response?.data?.error?.translated_message) {
          info = limitError(err.response?.data?.error?.translated_message);
        }
        if (err.response?.status === 401) {
          info = unauthorizedFollow(name, 'Folgen von', true);
        }
        dispatch(updateSnackbarInfo(info));
        setIsFollowed(false);
        if (setOpen) {
          setOpen(false);
        }
      });
  };

  const toggleFollow = () => {
    const entity = currentEntityFollowed ? currentEntityFollowed : followEntity;
    const entityType = getEntity(entity);
    if (entityType) {
      if (!!currentEntityFollowed) {
        setIsFollowed(false);
        updateFollowEntity(entityType, entity.id, true)
          .then(() => dispatch(fetchFollows()))
          .catch(() => {
            dispatch(updateSnackbarInfo(commonUpdateError));
            setIsFollowed(true);
          });
      } else {
        if (entityType === 'team' && entity.teams.length > 1 && setOpen) {
          setOpen(true);
        } else {
          setIsFollowed(true);
          const id = entityType === 'team' ? entity.teams[0].id : entity.id;
          updateFollowEntity(entityType, id, !!currentEntityFollowed, autoPush)
            .then(() => {
              const followedEntity = entityType === 'team' ? {...entity, ...entity.teams[0]} : entity;
              if (!currentEntityFollowed) {
                // Need to use entityType for "team" and not followedEntity.type
                collectFollowEntity(entityType, followedEntity.slug);
              }
              const name = entity.name || `${entity.firstName} ${entity.lastName}`;
              if (!autoPush && !disableAskForPush) {
                dispatch(askForEntityPush(followedEntity));
              } else {
                const message = autoPushSuccess(name);
                dispatch(updateSnackbarInfo(message));
              }
              dispatch(fetchFollows());
            })
            .catch(err => {
              const name = entity.name || `${entity.firstName} ${entity.lastName}`;
              let info = followError(name);
              if (err.response?.status === 400 && err.response?.data?.error?.translated_message) {
                info = limitError(err.response?.data?.error?.translated_message);
              }
              if (err.response?.status === 401 || err.status === 401) {
                info = unauthorizedFollow(name, 'Folgen von', true);
              }
              dispatch(updateSnackbarInfo(info));
              setIsFollowed(false);
            });
        }
      }
    } else {
      const name = entity.name ?? `${entity.firstName} ${entity.lastName}`;
      dispatch(updateSnackbarInfo(followError(name ?? 'Dieser Entität')));
    }
  };

  return {isFollowed, toggleFollow, handleSG};
};
