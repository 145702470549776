import React from 'react';
import styled from 'styled-components';
import {COLORS, Typography} from '@fupa/fupa-uikit';
import {formatDay, formatMonth} from 'app/helpers/dateHelpers';
import {
  ArrowDown,
  ArrowUp,
  Assist,
  Card,
  Football,
  TopEleven,
  Tracktics,
  TwoCards,
  PenaltyMissed,
} from 'app/components/icons';
import {Collections, PlayCircle, Timer} from '@mui/icons-material';
import {LinkOnComponent} from 'app/components/links/link';
import MatchProfileTeaser from 'app/components/profile/MatchProfileTeaser';

const Match = styled.div`
  border-right: 1px solid ${COLORS.lightGrey};
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border: 1px solid ${COLORS.lightGrey};
  border-radius: 4px;
`;

const Media = styled.div`
  display: flex;
  color: ${COLORS.darkerGrey};
  padding-left: 0.25rem;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MatchInfo = styled.div`
  display: flex;
  height: inherit;
  width: 100%;
`;

const MatchStats = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 1.5rem;
  height: 100%;
`;

const Stat = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 1.5rem;
  padding: 0 0.375rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
`;

const Count = styled(Typography)`
  &&& {
    margin-right: 0.125rem;
  }
`;

const Kickoff = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${COLORS.lightGrey};
`;

const Day = styled(Typography)`
  color: ${COLORS.black};
`;

const Month = styled(Typography)`
  text-transform: uppercase;
  color: ${COLORS.black};
`;

const iconStyle = {fontSize: 16, margin: 'auto'};
const statConfig = {
  // Player-Stats
  minutes: {icon: <Timer style={{...iconStyle, color: COLORS.darkGrey}} />},
  assists: {icon: <Assist style={{...iconStyle, color: COLORS.darkGrey}} />},
  topEleven: {icon: <TopEleven style={iconStyle} />, withoutCount: true},
  details: {icon: <Tracktics style={iconStyle} />, withoutCount: true},
  goals: {icon: <Football style={{...iconStyle, color: COLORS.darkGrey}} />},
  penaltyMisses: {icon: <PenaltyMissed style={{...iconStyle}} />},
  penaltyHits: {icon: <Football style={{...iconStyle, color: COLORS.green}} />},
  redCard: {icon: <Card style={{...iconStyle, color: COLORS.red}} />, withoutCount: true},
  substituteIn: {icon: <ArrowUp style={{...iconStyle, color: COLORS.greenIn}} />},
  substituteOut: {icon: <ArrowDown style={{...iconStyle, color: COLORS.red}} />},
  yellowCard: {icon: <Card style={{...iconStyle, color: COLORS.yellowCard}} />, withoutCount: true},
  yellowRedCard: {icon: <TwoCards style={iconStyle} />, withoutCount: true},
  // Referee-Stats
  redCards: {icon: <Card style={{...iconStyle, color: COLORS.red}} />},
  yellowCards: {icon: <Card style={{...iconStyle, color: COLORS.yellowCard}} />},
  yellowRedCards: {icon: <TwoCards style={iconStyle} />},
};

const ProfilePlayerMatchStats = ({ownTeamSlug, match, type}) => {
  const {statistics, flags, kickoff} = match;

  // 'minutes' need to be the first stat that should be rendered
  // Need to remove key 'minutes' from array first to change index to 0
  const statKeys = Object.keys(statistics).filter(element => element !== 'minutes');
  if (type === 'player') {
    statKeys?.unshift('minutes');
  }
  const stats = statKeys?.map(stat => {
    const filterAvailable = stat === 'details' ? statistics[stat]?.length > 0 : statistics[stat] > 0;
    if (filterAvailable && Boolean(statConfig[stat])) {
      let countValue = statistics[stat];
      countValue += stat === 'minutes' ? `'` : '';
      const count = statConfig[stat].withoutCount ? null : <Count variant='subheading'>{countValue}</Count>;
      return (
        <Stat key={stat}>
          {count}
          {statConfig[stat].icon}
        </Stat>
      );
    }
  });

  const Video = flags?.includes('fupatv') ? <PlayCircle style={{fontSize: '12px'}} /> : null;
  const Gallery = flags?.includes('gallery') ? <Collections style={{fontSize: '12px'}} /> : null;
  const MediaContent =
    Video || Gallery ? (
      <Media>
        {Video}
        {Gallery}
      </Media>
    ) : null;

  return (
    <Row>
      <Wrapper>
        <MatchInfo as={LinkOnComponent} to={`/match/${match.slug}`} enablehover={true}>
          <Match>
            <MatchProfileTeaser match={match} colored={type !== 'referee'} ownTeamSlug={ownTeamSlug} />
            <Kickoff>
              <Day variant='caption1'>{formatDay(kickoff)}.</Day>
              <Month variant='caption1'> {formatMonth(kickoff)}</Month>
              {MediaContent}
            </Kickoff>
          </Match>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              minHeight: '2.625rem',
            }}
          >
            <MatchStats>{stats}</MatchStats>
          </div>
        </MatchInfo>
      </Wrapper>
    </Row>
  );
};

export {ProfilePlayerMatchStats};
