import {sendEventToGA4} from 'app/helpers/gtagHelpers';

const collectFollowEntity = (entityType, entitySlug) => {
  sendEventToGA4('join_group', {
    group_type: entityType,
    group_id: entitySlug,
  });
};

export {collectFollowEntity};
