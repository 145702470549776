import React, {useState} from 'react';
import {convertToRoman} from 'app/helpers/convertToRoman';
import styled from 'styled-components';
import {ProfileStationsWithStats} from 'app/components/profile/profile.constants';
import {ELEVATIONS, SIZES, Image, COLORS, Typography, ToggleIcon} from '@fupa/fupa-uikit';
import {StyledTypography} from 'app/styles/content';
import {IconButton} from '@mui/material';
import {LinkOnComponent} from 'app/components/links/link';

const StyledLinkOnComponent = styled(LinkOnComponent)`
  display: flex;
  width: 100%;
  height: 100%;
  transform: skew(15deg);
`;

const MainContainer = styled.div`
  display: flex;
  flex: 0 100%;
  padding: ${SIZES['8']} 0;
  box-shadow: ${props => (props.showBoxShadow ? ELEVATIONS.ToggleHeader : 'none')};
`;

const Stats = styled.div`
  display: flex;
  flex: 1 50%;
`;

const SeasonContainer = styled.div`
  display: flex;
  min-width: 4.5rem;
  overflow: hidden;
  flex-direction: column;
`;

const CustomTypography = styled(Typography)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TeamContainer = styled.div`
  display: flex;
  flex: 1 50%;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  margin-left: ${SIZES['4']};
  ${props =>
    props.theme.desktopLayout`
     flex: ${props => (props.isCoach ? '1 30%' : '1 45%')};
    `};

  @media screen and (min-width: 375px) {
    min-width: 12rem;
  }
`;

const TeamWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 0.5rem;
  transform: skew(-15deg);
  background: ${COLORS.lightGrey};
  border-radius: 0.25rem;
  :hover,
  :focus {
    background: ${COLORS.grey};
  }
  @media only screen and (max-width: 450px) {
    max-width: 12rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 412px) {
    max-width: 11.25rem;
  }
  @media only screen and (min-width: 360px) and (max-width: 374px) {
    max-width: 10.25rem;
  }
  @media only screen and (max-width: 359px) {
    max-width: 9.825rem;
  }
  @media only screen and (max-width: 340px) {
    max-width: 9rem;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  flex: ${props => (!props.hasStatistics ? '1 50%' : 1)};
  min-width: ${props => (props.isCoach ? '4.5rem' : '6.5rem')};
  justify-content: space-around;
  align-items: center;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${SIZES['24']};
  height: ${SIZES['24']};
  border-radius: 50%;
  background: ${COLORS.white};
  margin: auto ${SIZES['6']} auto ${SIZES['6']};
`;

const TeamInformation = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  & > * {
    padding-left: 0;
    padding-right: 0.5rem;
  }
`;

const Team = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CoachStat = styled(Stat)`
  min-width: 3.5rem;
`;

const ToggleIconDesktop = styled.div`
  display: flex;
  justify-content: end;
  max-width: 4rem;
  align-items: center;
`;

const getCoachStats = (station, statisticKeys) => {
  const values = Object.keys(statisticKeys).map(key => station.statistics[key]);

  const keys = Object.keys(statisticKeys).map(key => statisticKeys[key]);
  const value = values.join('-');
  const label = keys.join('-');

  return (
    <CoachStat key={label}>
      <StyledTypography variant='body1' lineHeight={20}>
        {value}
      </StyledTypography>
      <Typography variant='caption1'>{label}</Typography>
    </CoachStat>
  );
};

const ProfileSeasonHeader = ({showBoxShadow, station, statisticKeys, onClick, role}) => {
  const [open, setOpen] = useState(false);
  const isCoach = role === 'coach';
  const hasStatistics = ProfileStationsWithStats.includes(station.position) || 'jerseyNumber' in station;
  let coachRole = null;
  let getStats;

  if (hasStatistics) {
    if (isCoach && station.position) {
      coachRole = (
        <SeasonContainer>
          <StyledTypography variant='body1' lineHeight={20}>
            {station.position}
          </StyledTypography>
          <CustomTypography variant='caption1'>Position</CustomTypography>
        </SeasonContainer>
      );
    }

    getStats = isCoach
      ? getCoachStats(station, statisticKeys)
      : Object.keys(statisticKeys).map(key => (
          <Stat key={key}>
            <StyledTypography variant='body1' lineHeight={20}>
              {station.statistics[key]}
            </StyledTypography>
            <Typography variant='caption1'>{statisticKeys[key]}</Typography>
          </Stat>
        ));
  } else {
    getStats = (
      <Stat>
        <StyledTypography variant='body1' lineHeight={20}>
          {station.position}
        </StyledTypography>
        <Typography variant='caption1'>Position</Typography>
      </Stat>
    );
  }

  const teamLevel = station.team.level > 1 ? <span>&nbsp;{convertToRoman(station.team.level)}</span> : null;

  const handleClick = () => {
    setOpen(!open);
    onClick();
  };

  return (
    <MainContainer hasstats={hasStatistics.toString()} showBoxShadow={showBoxShadow}>
      <TeamContainer>
        <TeamWrapper isCoach={isCoach}>
          <StyledLinkOnComponent to={`/team/${station.team.slug}`}>
            <ImageContainer>
              <Image entity='emblem' fit={true} src={station.team.image} width={24} height={24} />
            </ImageContainer>
            <TeamInformation>
              <Team>
                <StyledTypography variant='body1' useOverflow>
                  {station.team.name.middle}
                </StyledTypography>
                <Typography variant='body1'>{teamLevel}</Typography>
              </Team>
              <StyledTypography variant='caption1' useOverflow>
                {station.team.competition.middleName} {station.team.competition.season.name}
              </StyledTypography>
            </TeamInformation>
          </StyledLinkOnComponent>
        </TeamWrapper>
      </TeamContainer>
      <Stats>
        <StatsContainer hasStatistics={hasStatistics}>
          {coachRole}
          {getStats}
        </StatsContainer>
        {hasStatistics && (
          <ToggleIconDesktop onClick={handleClick}>
            <IconButton>
              <ToggleIcon open={open} style={{color: COLORS.darkGrey}} />
            </IconButton>
          </ToggleIconDesktop>
        )}
      </Stats>
    </MainContainer>
  );
};

export default ProfileSeasonHeader;
