import {playerStationFilter} from 'app/routes/player/PlayerPageReducer';
import {updateStationFilter} from 'app/routes/player/PlayerPageActions';
import {
  Chip,
  ChipIconContainer,
  ChipLabel,
  Chips,
  COLORS,
  ELEVATIONS,
  Line,
  LOADING_MESSAGE,
  LoadingIndicator,
  Placeholder,
  SIZES,
  Typography,
} from '@fupa/fupa-uikit';
import ProfileMatchesFilterContainer from 'app/components/profile/ProfileMatchesFilterContainer';
import React from 'react';
import styled from 'styled-components';
import {
  ArrowDown,
  ArrowUp,
  Assist,
  Card,
  Football,
  PenaltyMissed,
  TopEleven,
  Tracktics,
  TwoCards,
} from 'app/components/icons';
import {CaptionContainer} from 'app/styles/heading';

const LeagueBlock = styled.div`
  padding: ${SIZES[8]} 0;
  box-shadow: ${props => (props.show ? ELEVATIONS.ToggleBody : 'none')};
  ${props =>
    props.showsMatchRows &&
    `
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: ${SIZES[8]} ${SIZES[16]};
    gap: 0.5rem;
  `}
`;

const ProfileMatches = ({firstOpenedStation, station, role, matches, dispatch}) => {
  const profileSeasonId = station.id;
  const {items, isFetching, error, show, filter} = matches[profileSeasonId] ?? {
    items: null,
    isFetching: false,
    error: null,
    show: false,
    filter: playerStationFilter,
  };

  // FixMe: correct sorting needs to be done by api later
  const testspieleIndex = items?.findIndex(competition => competition.competition.slug === 'testspiele');
  let sortedCompetitions = items?.length ? [...items] : [];
  if (items?.length && testspieleIndex > -1) {
    sortedCompetitions?.push(sortedCompetitions.splice(testspieleIndex, 1).pop());
  }

  const setFilter = data => dispatch(updateStationFilter(data));

  let content = null;
  if (show) {
    const detail = role === 'coach' ? null : createFilterChips(station, filter, setFilter, profileSeasonId);

    const checkMatches = isFetching ? (
      <LoadingIndicator message={LOADING_MESSAGE.loadingMatches} bgColor />
    ) : !items.length && !isFetching && !error ? (
      <Placeholder show={show}>Noch keine Spiele eingetragen.</Placeholder>
    ) : error ? (
      <Placeholder show={show}>Beim Laden der Spiele ist ein Fehler aufgetreten.</Placeholder>
    ) : null;

    content = (
      <>
        {detail}
        <LeagueBlock showsMatchRows={role === 'coach'} show={show}>
          {items?.length > 0
            ? sortedCompetitions.map((matchGroup, index) => {
                if (matchGroup?.matches?.length) {
                  const matches = matchGroup.matches || [];
                  const reverseMatches = [...matches].reverse();
                  return (
                    <ProfileMatchesFilterContainer
                      firstOpenedStation={index === 0 ? firstOpenedStation : false}
                      key={matchGroup.competition.competitionSeasonId}
                      matches={reverseMatches}
                      competition={matchGroup.competition}
                      station={station}
                      filter={filter}
                      type={role}
                    />
                  );
                }
              })
            : checkMatches}
        </LeagueBlock>
        {show ? null : <Line />}
      </>
    );
  }

  return content;
};

const createFilterChips = (station, filter, setFilter, key) => {
  const iconStyle = {fontSize: 16, margin: 'auto'};
  const FilterGenerator = statisticKey =>
    ({
      assists: {label: 'Assist', icon: <Assist style={{...iconStyle, color: COLORS.darkGrey}} />},
      topEleven: {label: 'Elf d. W.', icon: <TopEleven style={iconStyle} />},
      details: {label: 'Laufdaten', icon: <Tracktics style={iconStyle} />},
      goals: {label: 'Tore', icon: <Football style={{...iconStyle, color: COLORS.darkGrey}} />},
      penaltyMisses: {label: 'versch. 11m', icon: <PenaltyMissed style={{...iconStyle, color: COLORS.red}} />},
      penaltyHits: {label: 'verwan. 11m', icon: <Football style={{...iconStyle, color: COLORS.green}} />},
      redCard: {label: 'Rot', icon: <Card style={{...iconStyle, color: COLORS.red}} />},
      substituteIn: {label: 'Ein.', icon: <ArrowUp style={{...iconStyle, color: COLORS.greenIn}} />},
      substituteOut: {label: 'Aus.', icon: <ArrowDown style={{...iconStyle, color: COLORS.red}} />},
      yellowCard: {label: 'Gelb', icon: <Card style={{...iconStyle, color: COLORS.yellowCard}} />},
      yellowRedCard: {label: 'Gelb-Rot', icon: <TwoCards style={iconStyle} />},
    }[statisticKey]);

  const updateFilter = (key, stat) => {
    if (stat in filter) {
      setFilter({key, filter: {...filter, [stat]: !filter[stat]}});
    }
  };

  const statisticFilterChip = Object.keys(station.statistics).map(stat => {
    const filterOptions = FilterGenerator(stat);
    const filterAvailable = stat === 'details' ? station.statistics[stat].length > 0 : station.statistics[stat] > 0;
    if (filterOptions && filterAvailable) {
      const dynamicLabelValue = stat === 'details' ? '' : station.statistics[stat];
      return (
        <Chip key={`${key}-${stat}`} active={filter[stat]} onClick={() => updateFilter(key, stat)}>
          <ChipIconContainer>{filterOptions.icon}</ChipIconContainer>
          <ChipLabel>{dynamicLabelValue + ' ' + filterOptions.label}</ChipLabel>
        </Chip>
      );
    }
  });
  // checks if filter chips available or not
  return !!statisticFilterChip.filter(chip => chip !== undefined).length ? (
    <>
      <CaptionContainer>
        <Typography variant='caption1'>Filter</Typography>
      </CaptionContainer>
      <Chips>{statisticFilterChip}</Chips>
    </>
  ) : null;
};

export default ProfileMatches;
