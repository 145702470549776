import React from 'react';
import styled from 'styled-components';
import {Image, ImageEntity, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';

interface Props {
  currentTeam: null | {
    name: string;
    url: string;
    image: any | null;
  };
}

const StyledProfileTeam = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  width: fit-content;
  border-radius: 2.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.5rem;
  min-height: 2.5rem;
`;

const Emblem = styled(Image)`
  &&& {
    height: 2rem;
    width: 2rem;
  }
`;

const EllipsisTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProfileTeam = ({currentTeam}: Props): JSX.Element => {
  let linkProps = {};
  if (!currentTeam) {
    return <StyledProfileTeam />;
  }
  if (currentTeam?.url) {
    linkProps = {
      as: LinkOnComponent,
      enablehover: true,
      to: currentTeam.url,
    };
  }

  return (
    <StyledProfileTeam {...linkProps}>
      <Emblem
        entity={ImageEntity.Emblem}
        src={currentTeam.image}
        ratio={'1:1'}
        height={32}
        width={32}
        eager={true}
        fit={true}
        fetchPriority={'high'}
      />
      <EllipsisTypography variant={'subheading'}>{currentTeam.name}</EllipsisTypography>
    </StyledProfileTeam>
  );
};
