import React, {useRef} from 'react';
import useDetectSticky from 'app/hooks/useDetectSticky';
import ProfileAppBar from 'app/components/player/PlayerHero/components/ProfileAppBar';
import {PlayerHero} from 'app/components/player/PlayerHero/PlayerHero';
import {TabBar} from 'app/components/tab/TabBar';
import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${COLORS.white};
  padding-top: env(safe-area-inset-top);
`;

const TabWrapper = styled.div`
  position: sticky;
  position: -webkit-sticky; /* Safari */
  transition: 0.2s ease-out;
  // set 'top: -1px' otherwise sticky event can not be triggered
  top: -1px;
  // set padding-top to avoid overlapping with sticky header
  padding-top: calc(3.5rem + env(safe-area-inset-top));
  // set margin-top to close gap between header and tabbar
  margin-top: calc(-3.5rem - env(safe-area-inset-top));
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 30;
  width: 100%;

  ${props => props.theme.desktopLayout`
    top: ${SIZES['72']};
    padding-top: 0;
    margin-top: 0;
 `}
`;

const ProfileHeroHeader = ({tabs, details}) => {
  const ref = useRef(null);
  const isSticky = useDetectSticky(ref);
  return (
    <>
      <Wrapper>
        <ProfileAppBar isSticky={isSticky} profile={details} />
        <PlayerHero profile={details} />
      </Wrapper>
      <TabWrapper ref={ref}>
        <TabBar withBg={true} disableHeaderCollapsing tabs={tabs} />
      </TabWrapper>
    </>
  );
};

export default ProfileHeroHeader;
