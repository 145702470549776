import React, {useEffect, useReducer} from 'react';
import {renderRoutes} from 'react-router-config';
import {Helmet} from 'react-helmet-async';
import {object} from 'prop-types';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {fetchPlayerMetaData} from 'app/routes/player/PlayerPageActions';
import {PlayerPageReducer} from 'app/routes/player/PlayerPageReducer';
import {useDispatch, useStore} from 'react-redux';
import {useParams} from 'react-router';
import {useFetchData, useRestoreData, useEffectAfterMount} from 'app/hooks/dataHooks';
import RedirectPlayerPage from 'app/routes/error/RedirectPlayerPage';
import {ErrorHandler} from 'app/components/error/ErrorHandler';
import ProfileHeroHeader from 'app/components/player/PlayerHero/ProfileHeroHeader';
import {initSessionReplayIntegration} from 'app/hooks/useSentrySessionReplay';

const PlayerPage = ({route}) => {
  const {playerSlug} = useParams();
  const {getState} = useStore();
  const dispatchRedux = useDispatch();

  const {restoredData} = useRestoreData('PlayerPage');
  const initialState = restoredData ?? {isFetching: false, data: {}};
  const [state, dispatch] = useReducer(PlayerPageReducer, initialState);
  const {data, isFetching} = state;

  const loadData = () => {
    fetchPlayerMetaData(playerSlug, dispatchRedux, getState)(dispatch, state);
  };

  useFetchData(state, loadData, 'PlayerPage', true);

  const playerName = `${data.firstName} ${data.lastName}`;
  const clubName = data.currentTeam?.club?.name ?? '';
  const competitionSeason = data.currentTeam ? data.currentTeam.competition.name : '';
  const defaultTitle = clubName ? playerName + ' - ' + clubName + TITLE_POSTFIX : playerName + TITLE_POSTFIX;
  const titleTemplate = `%s${defaultTitle}`;
  const conditionalMetaDescription = clubName ? ` des Vereins ${clubName} aus der Liga ${competitionSeason}` : '';
  const metaDescription = `${playerName} ist ein Fußballspieler${conditionalMetaDescription}. Aktuelle Bilanzen und alle Infos gibt es jetzt hier.`;

  // load new data to fill header when changing player (using Stream-Element)
  useEffectAfterMount(() => {
    loadData();
  }, [playerSlug]);

  useEffect(() => {
    initSessionReplayIntegration();
  }, []);

  const TabNames = [
    {
      name: 'Profil',
      url: `/player/${playerSlug}`,
    },
    {
      name: 'Rankings',
      url: `/player/${playerSlug}/rankings`,
    },
    {
      name: 'News',
      url: `/player/${playerSlug}/news`,
      hasSubUrl: true,
    },
  ];

  if (data.status === 410 && data.redirectSlug) {
    return <RedirectPlayerPage redirectSlug={data.redirectSlug} />;
  }

  const Header = <ProfileHeroHeader tabs={TabNames} details={data} />;
  return (
    <>
      <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate} />
      {Header}
      <ErrorHandler subroute={true}>
        {renderRoutes(route.routes, {
          defaultTitle,
          metaDescription,
          dispatchRedux,
          data,
          isFetching,
        })}
      </ErrorHandler>
    </>
  );
};

PlayerPage.propTypes = {
  route: object.isRequired,
};

export default PlayerPage;
