import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES, Image} from '@fupa/fupa-uikit';
import {object, bool, string} from 'prop-types';
import {Emblem} from 'app/components/icons';
import {MatchResultWithInfo} from 'app/components/match/MatchStreamTeaser';
import {getMatchRowColor} from 'app/components/match/matchRow/matchRowHelpers';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 42px;
  flex: 0 100%;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: 4px 0.5rem;
`;

const Team = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex: 1 35%;
  white-space: nowrap;
  overflow: hidden;

  img,
  svg {
    display: block;
    margin: auto 0.5rem;
  }

  &:first-child {
    justify-content: flex-end;
    justify-items: flex-end;
  }
  &:last-child {
    justify-content: flex-start;
    justify-items: flex-start;
  }
`;

const EmblemContainer = styled.div`
  width: ${SIZES[36]};
  height: ${SIZES[20]};
`;

const MatchProfileTeaser = ({match, colored, ownClubSlug, ownTeamSlug, lazyLoadWrapperId}) => {
  let matchRowColor = colored ? getMatchRowColor(match, ownClubSlug, ownTeamSlug) : COLORS.lightestGrey;

  const fallbackEmblem = <Emblem style={{color: COLORS.lightGrey, fontSize: 20}} />;

  const homeTeamData = match.homeTeam ? (
    <Team>
      <EmblemContainer>
        {match.homeTeam.image ? (
          <Image
            type={lazyLoadWrapperId ?? 'default'}
            entity='emblem'
            src={match.homeTeam.image}
            width={20}
            height={20}
            fit={true}
          />
        ) : (
          fallbackEmblem
        )}
      </EmblemContainer>
    </Team>
  ) : (
    <Team>
      <EmblemContainer>{fallbackEmblem}</EmblemContainer>
    </Team>
  );

  const awayTeamData = match.awayTeam ? (
    <Team>
      <EmblemContainer>
        {match.awayTeam.image ? (
          <Image
            type={lazyLoadWrapperId ?? 'default'}
            entity='emblem'
            src={match.awayTeam.image}
            width={20}
            height={20}
            fit={true}
          />
        ) : (
          fallbackEmblem
        )}
      </EmblemContainer>
    </Team>
  ) : (
    <Team>
      <EmblemContainer>{fallbackEmblem}</EmblemContainer>
    </Team>
  );

  return (
    <Container>
      {homeTeamData}
      <MatchResultWithInfo match={match} color={matchRowColor} />
      {awayTeamData}
    </Container>
  );
};

MatchProfileTeaser.propTypes = {
  match: object.isRequired,
  colored: bool.isRequired,
  ownClubSlug: string,
  ownTeamSlug: string,
};

export default MatchProfileTeaser;
