import React from 'react';
import {LinkToCompetition} from 'app/components/links/LinkToCompetition';
import styled from 'styled-components';
import {COLORS, Image, SIZES, Typography} from '@fupa/fupa-uikit';
import {generateMatchDayTitle} from 'app/helpers/actionsHelpers';
import {COMPETITION_IMAGE_FALLBACK} from 'app/helpers/helpers';
import {MatchCardFooter} from 'app/components/matchCalendar/MatchCardFooter';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.white};
  ${props => (props.hasFooter ? 'padding-bottom: 0;' : 'padding-bottom: 1rem;')}
  margin-bottom: ${SIZES[8]};
  ${props => props.theme.desktopLayout`
      border-radius: ${SIZES['4']};
      border: 1px solid ${COLORS.lightGrey};
      `}
`;

const Header = styled.div`
  display: flex;
  padding: ${SIZES[16]} ${SIZES[16]} ${SIZES[8]};
`;

const ImageContainer = styled.div`
  margin-right: ${SIZES[12]};
  width: ${SIZES[40]};
  height: ${SIZES[40]};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${SIZES[40]};
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  margin: 0;
  line-height: ${SIZES[24]};
`;

const StyledCaption = styled(Typography)`
  line-height: ${SIZES[16]};
`;

export const MatchdayCard = ({children, competitionGroup, extendWithAgeGroup, footer = true, className}) => {
  const {competition, matches, round} = competitionGroup;
  const caption = round ? generateMatchDayTitle(round) : generateMatchDayTitle(matches[0].round);
  const showCaption = caption ? <StyledCaption variant='caption1'>{caption}</StyledCaption> : null;
  const hasCompetitionImage = !competition.image.path.includes(COMPETITION_IMAGE_FALLBACK);
  const competitionName =
    !competition.active && competition.season?.name
      ? `${competition.name} ${competition.season.name}`
      : competition.name;

  const isLeague = competition.category.id === 1;

  const footerComp = footer ? <MatchCardFooter competition={competition} isLeague={isLeague} /> : null;
  return (
    <Wrapper className={className} hasFooter={footer}>
      <LinkToCompetition competition={competition}>
        <Header>
          <ImageContainer>
            <Image
              entity='competition'
              width={40}
              height={40}
              src={competition.image}
              circle={!hasCompetitionImage}
              fit={true}
            />
          </ImageContainer>
          <TitleContainer>
            <StyledTypography variant='subheading2'>
              {competitionName} {extendWithAgeGroup}
            </StyledTypography>
            {showCaption}
          </TitleContainer>
        </Header>
      </LinkToCompetition>
      {children}
      {footerComp}
    </Wrapper>
  );
};
