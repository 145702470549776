import {Button, COLORS, SIZES} from '@fupa/fupa-uikit';
import React, {useState, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import styled from 'styled-components';
import {Star, StarBorder} from '@mui/icons-material';
import {useFollow} from 'app/hooks/useFollow';
import {selectHydrationFinished} from 'app/hooks/reduxCreateSelectorHooks';

const TeamSelect = React.lazy(() => import('app/components/follow/TeamSelect'));

const DesktopButton = styled(Button)`
  &&& {
    height: ${SIZES['20']};
    min-width: auto;
    box-shadow: none;
    margin-left: 1rem;
    background-color: ${props => (props.followed === 'true' ? COLORS.fupaPrimaryFocused : COLORS.fupaSecondary)};
  }
`;

const selectHeaderEntity = createSelector(
  state => state.header,
  header => header.entity
);

const DefaultFollowButton = ({isDesktop, className}) => {
  const currentEntity = useSelector(selectHeaderEntity);
  const [open, setOpen] = useState(false);
  const hydrationFinished = useSelector(selectHydrationFinished);

  const {isFollowed, toggleFollow, handleSG} = useFollow(currentEntity, setOpen, false, 'entity_detail');

  const handleResolve = (teamId, teams) => {
    handleSG(teamId, teams);
  };

  const handleReject = () => setOpen(false);

  let element = isFollowed ? (
    <Star className={className} onClick={toggleFollow} style={{color: COLORS.white}} />
  ) : (
    <StarBorder className={className} onClick={toggleFollow} style={{color: COLORS.white}} />
  );
  const label = isFollowed ? 'Abonniert' : 'Folgen';

  if (isDesktop) {
    element = (
      <DesktopButton className={className} label={label} onClick={toggleFollow} followed={isFollowed.toString()} />
    );
  }

  const renderTeamSelect =
    !isFollowed && currentEntity.entity === 'team' && currentEntity.teams.length > 1 ? (
      <TeamSelect
        teams={currentEntity.teams}
        onResolve={teamId => handleResolve(teamId, currentEntity.teams)}
        onReject={handleReject}
        open={open}
      />
    ) : null;

  const teamSelect = hydrationFinished ? <Suspense fallback={<div />}>{renderTeamSelect}</Suspense> : <div />;

  return (
    <>
      {element}
      {teamSelect}
    </>
  );
};

const DesktopFollowButton = styled(DefaultFollowButton)`
  &&& {
    display: none;
    ${props => props.theme.desktopLayout`
      display: flex;
    `}
  }
`;

const MobileFollowButton = styled(DefaultFollowButton)`
  &&& {
    display: flex;
    align-self: flex-end;
    ${props => props.theme.desktopLayout`
      display: none;
    `}
  }
`;

const FollowButton = ({adjustStyles, ...props}) => {
  if (adjustStyles) {
    return adjustStyles === 'mobile' ? <MobileFollowButton {...props} /> : <DesktopFollowButton {...props} />;
  } else {
    return <DefaultFollowButton {...props} />;
  }
};

export default FollowButton;
