import React from 'react';
import styled from 'styled-components';
import {Subheading} from 'app/styles/heading';
import {COLORS, ELEVATIONS, SIZES, Typography} from '@fupa/fupa-uikit';
import RefereeSeason from 'app/components/referee/RefereeSeason';

const Container = styled.div`
  background-color: ${COLORS.white};
  box-shadow: ${ELEVATIONS.Card};
  margin-top: ${SIZES['8']};
`;

const Header = styled.div`
  padding-bottom: ${SIZES['16']};
`;

const CustomSubheading = styled(Subheading)`
  &&& {
    margin: 0;
  }
`;

const Stats = styled.div`
  display: flex;
  flex: 0 100%;
  height: ${SIZES['48']};
  align-items: center;
  border-top: 1px solid ${COLORS.fupaSecondary};
`;

const Stat = styled.div`
  flex: 1 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RefereeStations = ({refereeRole, ...props}) => {
  const totalStatistics = refereeRole.total ? refereeRole.total : {};
  const seasons = refereeRole.seasons ? refereeRole.seasons : [];
  const refereeSeasons = seasons.map(station => (
    <RefereeSeason key={station.season.slug} station={station} {...props} />
  ));

  return (
    <Container>
      <Header>
        <CustomSubheading variant='subheading2'>Stationen</CustomSubheading>
      </Header>
      {refereeSeasons}
      <Stats>
        <Stat>
          <Typography variant='body1'>{totalStatistics.yellowCards}</Typography>
          <Typography variant='caption1'>Gelb</Typography>
        </Stat>
        <Stat>
          <Typography variant='body1'>{totalStatistics.yellowRedCards}</Typography>
          <Typography variant='caption1'>Gelb-Rot</Typography>
        </Stat>
        <Stat>
          <Typography variant='body1'>{totalStatistics.redCards}</Typography>
          <Typography variant='caption1'>Rot</Typography>
        </Stat>
        <Stat>
          <Typography variant='body1'>{totalStatistics.matches}</Typography>
          <Typography variant='caption1'>Spiele</Typography>
        </Stat>
      </Stats>
    </Container>
  );
};

export default RefereeStations;
