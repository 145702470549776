import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Button, COLORS, SIZES, Typography} from '@fupa/fupa-uikit';
import {Content} from 'app/styles/content';
import {useHistory} from 'react-router';
import NotFoundPage from 'app/routes/error/NotFoundPage';
import {Helmet} from 'react-helmet-async';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';

const Wrapper = styled(Content)`
  &&& {
    padding: ${SIZES[72]} ${SIZES['16']} 0;
    height: 100vw;
    text-align: center;
    box-sizing: content-box;
  }
`;

const SubTitle = styled(Typography)`
  padding: ${SIZES['48']} 0 ${SIZES['16']};
  display: block;
`;

const Text = styled(Typography)`
  &&& {
    color: ${COLORS.darkGrey};
  }
`;

const ButtonContainer = styled.div`
  padding: ${SIZES['32']};
`;

const TIMEOUT_VALUE = 5;

const RedirectPlayerPage = ({redirectSlug}) => {
  const history = useHistory();
  const link = redirectSlug ? `/player/${redirectSlug}` : null;
  const [count, setCount] = useState(TIMEOUT_VALUE);

  const handleRedirect = () => {
    history.replace(link);
  };

  useEffect(() => {
    let timeout, interval;
    if (link) {
      timeout = setTimeout(handleRedirect, TIMEOUT_VALUE * 1000);
      interval = setInterval(() => setCount(count => count - 1), 1000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
      if (interval) clearInterval(interval);
    };
  }, []);

  const description = 'Du wirst weitergeleitet...';

  const title = description + TITLE_POSTFIX;
  const meta = [
    {name: 'robots', content: 'noindex, follow'},
    {name: 'description', content: description},
  ];

  // Id used to check in renderer.js if component is rendered for SSR
  const content = link ? (
    <Wrapper id='gone-page'>
      <SubTitle variant='headline2'>Dieses Profil ist umgezogen. 🧳</SubTitle>
      <Text variant='subheading' component='p'>
        Du wirst in {count > 0 ? count : 0} Sekunden automatisch zur neuen Adresse weitergeleitet.
      </Text>
      <Text variant='subheading' component='p'>
        Solltest du nicht automatisch weitergeleitet werden, dann klicke bitte hier:
      </Text>
      {link ? (
        <ButtonContainer>
          <Button label='Zum Profil' onClick={handleRedirect} justify='center' />
        </ButtonContainer>
      ) : null}
    </Wrapper>
  ) : (
    <NotFoundPage id='gone-page' />
  );

  return (
    <>
      <Helmet title={title} meta={meta} />
      {content}
    </>
  );
};

export default RedirectPlayerPage;
