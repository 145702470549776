import styled from 'styled-components';
import {Subheading} from 'app/styles/heading';
import {SIZES} from '@fupa/fupa-uikit';

const MatchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  ${props => props.theme.desktopLayout`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
`}
  ${Subheading} {
    flex: 100%;
  }
`;

const MatchesPagesWrapper = styled(MatchesWrapper)`
  padding: 0 0.5rem;
`;

const MatchRowsCardWrapper = styled(MatchesWrapper)`
  padding: ${SIZES[4]} ${SIZES[4]};

  ${props => props.theme.desktopLayout`
    padding: ${SIZES[4]} ${SIZES[16]};
`}
`;

export {MatchRowsCardWrapper, MatchesPagesWrapper};
