import React from 'react';
import styled from 'styled-components';
import {SIZES, Typography} from '@fupa/fupa-uikit';
import {CaptionContainer, CompetitionCaption} from 'app/styles/heading';
import {object, bool, objectOf, array, string} from 'prop-types';
import {ProfilePlayerMatchStats} from 'app/components/profile/ProfilePlayerMatchStats';
import {MatchRow} from 'app/components/match/matchRow/MatchRow';
import {MatchdayCard} from 'app/components/matchCalendar/matchdayCard';
import {formatDateWithWeekday} from 'app/helpers/dateHelpers';
import {MatchRowsCardWrapper} from 'app/components/match/matchRow/MatchRowStyles';
import MobileBannerAdSlot from 'app/components/ads/MobileBannerAdSlot';

const MatchContainer = styled.div`
  width: 100%;
  padding: ${SIZES['8']} 0;
`;

const SCMatchdayCard = styled(MatchdayCard)`
  flex: 1;
  height: fit-content;
  gap: 0.5rem;
`;

const checkAvailableMatchesForActiveFilter = (activeFilter, filterArray) => {
  return activeFilter === 'details' && filterArray[activeFilter]
    ? filterArray[activeFilter].length > 0
    : filterArray[activeFilter] > 0;
};

const filterMatches = (matches, filter) => {
  let filteredMatches = [];
  const activeFilters = Object.keys(filter).filter(key => filter[key]);
  if (activeFilters.length && matches.length) {
    matches.map(match => {
      const add = match.statistics
        ? activeFilters.some(
            activeFilter =>
              activeFilter in match.statistics && checkAvailableMatchesForActiveFilter(activeFilter, match.statistics)
          )
        : match.referee && match.referee.statistics
        ? activeFilters.some(
            activeFilter =>
              activeFilter in match.referee.statistics &&
              checkAvailableMatchesForActiveFilter(match.referee.statistics, activeFilter)
          )
        : null;
      if (add) {
        filteredMatches.push(match);
      }
    });
  } else {
    filteredMatches = matches;
  }
  return filteredMatches;
};

const ProfileMatchesFilterContainer = ({firstOpenedStation, matches, competition, station, filter, type}) => {
  const showsMatchRows = type === 'coach';

  const filteredMatches = filterMatches(matches, filter).map(match => {
    return showsMatchRows ? (
      <MatchRow
        key={match.slug}
        match={match}
        caption={
          <CompetitionCaption variant='caption1'>{formatDateWithWeekday(match.kickoff, true)}</CompetitionCaption>
        }
        colored={true}
        ownTeamSlug={station.team.slug}
      />
    ) : (
      <ProfilePlayerMatchStats key={match.slug} ownTeamSlug={station.team?.slug ?? null} match={match} type={type} />
    );
  });

  if (filteredMatches.length === 0) {
    return null;
  }
  if (!showsMatchRows && firstOpenedStation === station.id) {
    filteredMatches.splice(2, 0, <MobileBannerAdSlot key={'ad-Mobile_Banner'} />);
  }

  return showsMatchRows ? (
    <SCMatchdayCard
      competitionGroup={{competition, matches}}
      extendWithAgeGroup={`| ${competition.district.name}`}
      footer={false}>
      <MatchRowsCardWrapper>{filteredMatches}</MatchRowsCardWrapper>
    </SCMatchdayCard>
  ) : (
    <>
      <CaptionContainer>
        <Typography variant='caption1'>
          {competition.name} | {competition.district.name}
        </Typography>
      </CaptionContainer>
      <MatchContainer>{filteredMatches}</MatchContainer>
    </>
  );
};

ProfileMatchesFilterContainer.propTypes = {
  matches: array.isRequired,
  competition: object.isRequired,
  station: object.isRequired,
  filter: objectOf(bool).isRequired,
  type: string,
};

export default ProfileMatchesFilterContainer;
