import React from 'react';
import styled from 'styled-components';
import {Typography} from '@fupa/fupa-uikit';

interface Props {
  firstName: string;
  lastName: string;
}

const StyledPlayerName = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  height: 3.25rem;
  line-height: 1.3;
`;

const SCTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PlayerName = ({firstName, lastName}: Props): JSX.Element => {
  return (
    <StyledPlayerName>
      <SCTypography variant={'subheading2'} component={'span'}>
        {firstName}
      </SCTypography>
      <SCTypography variant={'headline2'} component={'span'}>
        {lastName}
      </SCTypography>
    </StyledPlayerName>
  );
};
