import React from 'react';
import {COLORS, SIZES, Image, Typography, FONTS} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {Back} from 'app/components/appBar/back';
import {Edit} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';

const StyledProfileTeam = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.5rem;
  min-height: 2rem;
`;

const Avatar = styled(Image)`
  border-radius: 50%;
  border: 1px solid ${COLORS.lighterGrey};
  margin-right: 0.75rem;
`;

const EllipsisTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${FONTS.bold};
`;

const SmallHeaderBar = styled.div`
  position: fixed;
  top: env(safe-area-inset-top);
  box-sizing: content-box;
  display: flex;
  width: inherit;
  background-color: ${COLORS.white};
  height: ${SIZES['56']};
  justify-content: space-between;
  align-items: center;
  z-index: 60;
  ${props => props.theme.desktopLayout`
    display: none;
  `}
`;

const BackWrapper = styled.div`
  width: ${SIZES['72']};
`;

const Content = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  align-content: flex-start;
  justify-content: center;
  height: inherit;
  visibility: ${props => (props.isSticky ? 'visible' : 'hidden')};
`;

const ProfileAppBar = ({profile, isSticky}) => {
  const isAuthenticated = useSelector(selectAuthState);

  const handleEdit = () => {
    window.open(`${process.env.ADMIN_URL}/fupa/admin/spielerprofil.php?spieler=${profile.id}`);
  };

  const EditButton =
    isAuthenticated && profile?.id ? (
      <IconButton color='action' onClick={handleEdit} size='large'>
        <Edit color='inherit' />
      </IconButton>
    ) : null;

  const content = (
    <StyledProfileTeam>
      <Avatar
        entity={'player'}
        src={profile.image}
        ratio={'1:1'}
        height={32}
        width={32}
        eager={true}
        fit={true}
        fetchPriority={'high'}
      />
      <EllipsisTypography variant={'body2'}>
        {profile?.firstName} {profile?.lastName}
      </EllipsisTypography>
    </StyledProfileTeam>
  );

  return (
    <SmallHeaderBar>
      <BackWrapper>
        <Back color={COLORS.darkGrey} />
      </BackWrapper>
      <Content isSticky={isSticky}>{content}</Content>
      {EditButton}
    </SmallHeaderBar>
  );
};

export default ProfileAppBar;
