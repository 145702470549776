import React, {useRef} from 'react';
import styled from 'styled-components';
import {FONTS, SIZES, Typography} from '@fupa/fupa-uikit';
import {ProfileStationsWithStats} from 'app/components/profile/profile.constants';
import {AdSlot} from 'app/components/ads/AdSlot';
import {TeamSeasonStation} from 'app/components/profile/TeamSeasonStation';

const Container = styled.div`
  padding-bottom: 1.5rem;
`;

const Header = styled.div`
  padding: ${SIZES['16']} 0;
`;

const Heading2 = styled(Typography)`
  &&& {
    text-transform: uppercase;
    font-weight: ${FONTS.bold};
    padding-left: 1rem;
  }
`;

const Bilanz = styled(Typography)`
  &&& {
    display: inline-flex;
    padding: 0 1rem;
  }
`;

const coachTotalStatisticKeys = {
  matches: 'Spiele',
  wins: 'Siege',
  draws: 'Remis',
  defeats: 'Niederlagen',
  points: 'Punkte',
};

const playerTotalStatisticKeys = {
  matches: 'Spiele',
  goals: 'Tore',
  assists: 'Assists',
  yellowCard: 'Gelb',
  yellowRedCard: 'Gelb-Rot',
  redCard: 'Rot',
  topEleven: 'Elf d. W.',
};

const getProfileRoleStats = ({statisticValues, statisticKeys}) => {
  const getStats = Object.keys(statisticKeys).map(key => `${statisticValues[key]} ${statisticKeys[key]}`);
  return getStats.join(', ');
};

const ProfileStations = ({role, stations, adUnits, ...props}) => {
  const firstOpenedStation = useRef(null);

  const updateFirstOpenedStation = station => {
    if (!firstOpenedStation.current) {
      firstOpenedStation.current = station;
    }
  };

  const ProfileStations = stations.seasons.map(season => (
    <TeamSeasonStation
      updateFirstOpenedStation={updateFirstOpenedStation}
      firstOpenedStation={firstOpenedStation?.current}
      key={season.id}
      station={season}
      role={role === 'Trainer' ? 'coach' : 'player'}
      {...props}
    />
  ));

  const hasTotalStatistics = stations.seasons.find(
    season => ProfileStationsWithStats.indexOf(season.position ?? 'Spieler') >= 0
  );

  const totalStatisticsNew = hasTotalStatistics
    ? getProfileRoleStats({
        statisticValues: stations.total,
        statisticKeys: role === 'Trainer' ? coachTotalStatisticKeys : playerTotalStatisticKeys,
      })
    : null;

  if (adUnits) {
    adUnits.map((adUnit, index) => {
      // 5. Elem, 10.Elem, 15.Elem, 20.Elem
      const spliceAt = index === 0 ? 5 : 5 * (index + 1) + 1;
      if (ProfileStations.length + 1 > spliceAt) {
        ProfileStations.splice(spliceAt, 0, <AdSlot key={adUnit} adUnit={adUnit} />);
      }
    });
  }

  return (
    <Container>
      <Header>
        <Heading2 variant='caption1'>{role}stationen</Heading2>
        <br />
        {hasTotalStatistics && <Bilanz variant='caption1'>Bilanz: {totalStatisticsNew}</Bilanz>}
      </Header>
      {ProfileStations}
    </Container>
  );
};

export {ProfileStations};
