import {api} from 'app/services/api';
import {changeHeaderText} from 'app/actions/headerActions';
import {catchDataError, checkApiRedirect, RedirectException} from 'app/helpers/apiHelpers';
import {updateHistory} from 'app/actions/historyActions';
import {trySettingDefaultDistrict} from 'app/actions/sessionActions';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';

const getReferee = refereeSlug => {
  return api.get(`/referees/${refereeSlug}`);
};

const getMatchesByRefereeStation = (refereeSlug, seasonSlug) => {
  return api.get(`/referees/${refereeSlug}/seasons/${seasonSlug}/matches`);
};

function fetchingReferee() {
  return {
    type: 'REFEREE_FETCHING',
  };
}

function updateReferee(data) {
  return {
    type: 'REFEREE_UPDATE',
    data,
  };
}

function updateStationShow(data) {
  return {
    type: 'REFEREE_STATION_SHOW_UPDATE',
    data,
  };
}

function updateStationFilter(data) {
  return {
    type: 'REFEREE_STATION_FILTER_UPDATE',
    data,
  };
}

function setRefereeHeader(dispatch, referee) {
  let parent = {
    name: 'ohne Verein',
    entity: '',
  };

  if (referee.club) {
    parent = {
      name: referee.club.name,
      entity: 'club',
      slugs: {
        clubSlug: referee.club.slug,
      },
    };
  }

  dispatch(
    changeHeaderText({
      title: `${referee.firstName} ${referee.lastName}`,
      parent,
    })
  );
}

const trySettingDefaultDistrictFromReferee = (referee, dispatch, getState) => {
  const defaultDistrictEntity = referee.group ?? referee.club ?? null;
  if (defaultDistrictEntity) {
    return trySettingDefaultDistrict(defaultDistrictEntity, dispatch, getState);
  }
};

function fetchRefereeSSR(refereeSlug) {
  return async function (dispatch, getState) {
    try {
      const response = await getReferee(refereeSlug);
      const referee = response.data;
      checkApiRedirect(referee.slug, refereeSlug, null, null, getState);
      dispatch(
        updateHistory('undefined', {
          store: 'RefereePage',
          data: {referee, isFetching: false, error: null, matches: []},
        })
      );
      setRefereeHeader(dispatch, referee);
      return trySettingDefaultDistrictFromReferee(referee, dispatch, getState);
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatch(updateHttpError(errorData));
      }
    }
  };
}

function fetchReferee(refereeSlug, dispatchRedux, getState) {
  return function (dispatch, state) {
    if (Object.keys(state.referee).length) {
      setRefereeHeader(dispatchRedux, state.referee);
      return;
    }
    if (state.error) {
      return;
    }
    dispatch(fetchingReferee());
    return getReferee(refereeSlug)
      .then(response => {
        const referee = response.data;
        checkApiRedirect(referee.slug, refereeSlug, null, null, null);
        dispatch(updateReferee(referee));
        setRefereeHeader(dispatchRedux, referee);
        return trySettingDefaultDistrictFromReferee(referee, dispatchRedux, getState);
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatchRedux(updateHttpError(errorData));
        }
      });
  };
}

function updateStationMatches(data) {
  return {
    type: 'REFEREE_STATION_MATCHES_UPDATE',
    data,
  };
}

function errorStationMatches(data) {
  return {
    type: 'REFEREE_STATION_MATCHES_ERROR',
    data,
  };
}

function fetchingStationMatches(data) {
  return {
    type: 'REFEREE_STATION_MATCHES_FETCHING',
    data,
  };
}

function fetchRefereeStationMatches(refereeSlug, seasonSlug) {
  return function (dispatch, state) {
    const {matches} = state;
    const key = seasonSlug;
    if (key in matches) {
      return;
    }
    dispatch(fetchingStationMatches({key}));
    return getMatchesByRefereeStation(refereeSlug, seasonSlug)
      .then(response => {
        dispatch(updateStationMatches({key, items: response.data}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(errorStationMatches({key, error: errorData}));
      });
  };
}

export {fetchRefereeSSR, fetchReferee, fetchRefereeStationMatches, updateStationShow, updateStationFilter};
